import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { isArray, isEmpty } from "lodash";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import MDetail from "../../../Components/Profile/Modal/MDetail";
import MRate from "../../../Components/Modal/RateModal";
import authActions from "../../../Redux/reducers/auth/actions";
import { isIOS } from "react-device-detect";

export default function Company() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setRating } = authActions;
  const { token, dropdowndata, rating } = useSelector((state) => state.auth);

  const [videoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const [uploadLoader, setUploadLoader] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [rateModal, setRateModal] = useState(false);
  const [activeJob, setActiveJob] = useState({
    data: [],
    pageData: {},
  });
  const [inActiveJob, setInActiveJob] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [sector, setSector] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [selectedTab, setSelectedTab] = useState(0); // State for Tabs

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const header = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_COMPANY;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token !== "") {
      getCompanyDetails();
      getActiveJobList();
      getInActiveJobList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Function to get company details
  async function getCompanyDetails() {
    setPageLoad(true);
    try {
      const response = await getApiData(
          Setting.endpoints.employerCompanyDetails,
          "GET",
          {},
          header
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          dispatch(setRating(response?.data?.rating));
          setCompanyDetail(response.data);
          setVideoUrl(response?.data?.company_video);
          getDropdownData(response.data);
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // Function to get active job list
  async function getActiveJobList() {
    try {
      const response = await getApiData(
          `${Setting.endpoints.employerJobList}?job_status=1`,
          "GET",
          {},
          header
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setActiveJob({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  async function getInActiveJobList() {
    try {
      const response = await getApiData(
          `${Setting.endpoints.employerInActiveJobList}?job_status=1`,
          "GET",
          {},
          header
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setInActiveJob({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  // Function to get dropdown data
  function getDropdownData(data) {
    dropdowndata.sectors.forEach((ii) => {
      if (Number(ii?.value) === Number(data?.sector)) {
        setSector(ii?.label);
      }
    });

    dropdowndata.company_size.forEach((ii) => {
      if (Number(ii?.value) === Number(data?.company_size)) {
        setCompanySize(ii?.label);
      }
    });
  }

  // Function to preview profile
  function previewProfile() {
    navigate("/company/profile", { state: { from: "preview" } });
  }

  // Function to view candidates
  function viewCandidates() {
    navigate("/employer/jobs/candidates");
  }

  // Function to post a new job
  function postJob() {
    navigate("/employer/jobs/post-job");
  }

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Function to render jobs
  const renderJobs = (jobs, type) => (
      <Grid container spacing={2}>
        {isArray(jobs) && jobs.length === 0 ? (
            <Grid item xs={12}>
              <Typography className="lightsilvercolor regulerlargefont">
                {type === "active"
                    ? "Post your new job"
                    : "No inactive jobs available"}
              </Typography>
              {type === "active" && (
                  <Button
                      variant="contained"
                      style={{ width: 200, marginTop: 14 }}
                      onClick={postJob}
                  >
                    POST NEW JOB
                  </Button>
              )}
            </Grid>
        ) : (
            <>
              {isArray(jobs) &&
                  jobs.length > 0 &&
                  jobs.slice(0, 5).map((item, index) => (
                      <Grid
                          item
                          xs={12}
                          md={6}
                          key={`${item?.sector || ""}_${index}`}
                          className={[classes.detailmain, classes.paddingmain]}
                      >
                        <Typography
                            className="primarycolor semibold18"
                            style={{ marginBottom: 16 }}
                        >
                          {item?.title || ""}
                        </Typography>
                        <div className={classes.activeDetail}>
                          <div style={{ marginRight: "20px" }}>
                            <Typography className="regulerlargefont lightsilvercolor">
                              Sector
                            </Typography>
                            <Typography className="reguler20 primarycolor">
                              {item?.sector || "-"}
                            </Typography>
                          </div>
                          <div>
                            <Typography className="regulerlargefont lightsilvercolor">
                              Role
                            </Typography>
                            <Typography className="reguler20 primarycolor">
                              {item?.job_role_name || "-"}
                            </Typography>
                          </div>
                        </div>
                        <Button
                            variant="outlined"
                            style={{ width: "100%", marginTop: "16px" }}
                            onClick={() => {
                              navigate("/employer/jobs/post-job", {
                                state: { jobId: item?.id },
                              });
                            }}
                        >
                          VIEW JOB
                        </Button>
                        <Button
                            variant="contained"
                            style={{ width: "100%", marginTop: "10px" }}
                            onClick={() => {
                              navigate("/employer/jobs/matching-job", {
                                state: { jobId: item?.id, data: item },
                              });
                            }}
                        >
                          VIEW MATCHING
                        </Button>
                      </Grid>
                  ))}
              {isArray(jobs) && jobs.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={() => {
                          navigate("/employer/jobs");
                        }}
                    >
                      VIEW ALL JOB POSTS
                    </Button>
                  </Grid>
              )}
            </>
        )}
      </Grid>
  );

  const renderInactiveJobs = (jobs) => (
      <Grid container spacing={2}>
        {(
            <>
              {isArray(jobs) &&
                  jobs.length > 0 &&
                  jobs.map((item, index) => (
                      <Grid
                          item
                          xs={12}
                          md={6}
                          key={`${item?.sector || ""}_${index}`}
                          className={[classes.detailmain, classes.paddingmain]}
                      >
                        <Typography
                            className="primarycolor semibold18"
                            style={{ marginBottom: 16 }}
                        >
                          {item?.title || ""}
                        </Typography>
                        <div className={classes.activeDetail}>
                          <div style={{ marginRight: "20px" }}>
                            <Typography className="regulerlargefont lightsilvercolor">
                              Sector
                            </Typography>
                            <Typography className="reguler20 primarycolor">
                              {item?.sector || "-"}
                            </Typography>
                          </div>
                          <div>
                            <Typography className="regulerlargefont lightsilvercolor">
                              Role
                            </Typography>
                            <Typography className="reguler20 primarycolor">
                              {item?.job_role_name || "-"}
                            </Typography>
                          </div>
                        </div>
                        <Button
                            variant="outlined"
                            style={{ width: "100%", marginTop: "16px" }}
                            onClick={() => {
                              navigate("/employer/jobs/post-job", {
                                state: { jobId: item?.id },
                              });
                            }}
                        >
                          VIEW JOB
                        </Button>
                        <Button
                            variant="contained"
                            style={{ width: "100%", marginTop: "10px" }}
                            onClick={() => {
                              navigate("/employer/jobs/matching-job", {
                                state: { jobId: item?.id, data: item },
                              });
                            }}
                        >
                          VIEW MATCHING
                        </Button>
                      </Grid>
                  ))}
              {/*{isArray(jobs) && jobs.length > 0 && (*/}
              {/*    <Grid item xs={12}>*/}
              {/*      <Button*/}
              {/*          variant="contained"*/}
              {/*          style={{ width: "100%" }}*/}
              {/*          onClick={() => {*/}
              {/*            navigate("/employer/jobs");*/}
              {/*          }}*/}
              {/*      >*/}
              {/*        VIEW ALL JOB POSTS*/}
              {/*      </Button>*/}
              {/*    </Grid>*/}
              {/*)}*/}
            </>
        )}
      </Grid>
  );
  // Function to render the left side (Company Details and Jobs)
  function renderLeft() {
    return (
        <Grid item xs={12} sm={8} md={9}>
          <Container className={classes.detailContainer}>
            {/* Company Details Section */}
            <Grid
                item
                xs={12}
                className={[classes.detailmain, classes.paddingmain]}
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={3} className={classes.topimgmain}>
                  <img
                      src={companyDetail?.company_logo}
                      className={classes.companyimgmain}
                      alt="Company Logo"
                  />
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={9}
                    direction="column"
                    className={classes.editprofilemain}
                >
                  <Grid container>
                    <div className={classes.viewBtnContainer}>
                      <Grid item xs={12} sm={6} md={5.6}>
                        <Button
                            variant="contained"
                            className={classes.viewCandidateBtnStyle}
                            onClick={viewCandidates}
                        >
                          MY CANDIDATES
                        </Button>
                      </Grid>
                    </div>
                    <Grid item xs={12}>
                      <Typography
                          className="primarycolor mediumsemiboldfont"
                          style={{ marginTop: 10 }}
                      >
                        {companyDetail?.company_name || "-"}
                      </Typography>
                      <Typography
                          className="lightsilvercolor regulerfont"
                          style={{ marginBottom: 10 }}
                      >
                        {companyDetail?.tag_line || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={5.6}
                        style={{ marginRight: sm ? 0 : 10, marginBottom: 10 }}
                    >
                      <Link
                          href="/company/profile"
                          prefetch={false}
                          style={{ textDecoration: "none" }}
                      >
                        <Button variant="outlined" style={{ width: "100%" }}>
                          EDIT PROFILE
                        </Button>
                      </Link>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5.6}
                        md={5.6}
                        style={{ marginBottom: 10 }}
                    >
                      <Button
                          variant="outlined"
                          style={{ width: "100%" }}
                          onClick={previewProfile}
                      >
                        PREVIEW PROFILE
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Jobs Section with Tabs */}
            <Grid
                item
                xs={12}
                className={[classes.detailmain, classes.paddingmain]}
            >
              <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Active and Inactive Jobs Tabs"
                  centered
              >
                <Tab label="Active Jobs" />
                <Tab label="Inactive Jobs" />
              </Tabs>

              {/* Content for the selected tab */}
              {selectedTab === 0 ? (
                  renderJobs(activeJob?.data, "active")
              ) : (
                  renderInactiveJobs(inActiveJob?.data)
              )}
            </Grid>
          </Container>
        </Grid>
    );
  }

  // Function to render the right side (Company Details Sidebar)
  function renderRight() {
    return (
        <Grid item xs={12} sm={4} md={3}>
          <div className={classes.detailmain}>
            <div className={classes.detailfirst}>
              <Typography
                  className="primarycolor"
                  style={{
                    fontFamily: "PoppinsRegular",
                  }}
              >
                Details
              </Typography>
            </div>
            <div className={classes.detaildivider} />
            <div className={classes.detailsecond}>
              <div className={classes.cDetailSection}>
                <Typography
                    className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                >
                  Company size
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {companySize || 0}
                </Typography>
              </div>
              {/* Uncomment if needed
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Culture
              </Typography>
              <Typography className={classes.sectionDesc}>Startup</Typography>
            </div>
            */}
              <div className={classes.cDetailSection}>
                <Typography
                    className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                >
                  Industry
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {sector || "-"}
                </Typography>
              </div>
              <div className={classes.cDetailSection}>
                <Typography
                    className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                >
                  Website
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {companyDetail?.company_website || "-"}
                </Typography>
              </div>
              {(companyDetail?.facebook_url ||
                  companyDetail?.instagram_url ||
                  companyDetail?.linkedin_url ||
                  companyDetail?.twitter_url) && (
                  <div
                      className={classes.cDetailSection}
                      style={{ marginBottom: 0 }}
                  >
                    <Typography
                        className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                    >
                      Follow us
                    </Typography>
                    <div className={classes.socialContainer}>
                      {companyDetail?.facebook_url && (
                          <a
                              href={companyDetail.facebook_url}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <div className={classes.socialDiv}>
                              <img
                                  alt="Facebook"
                                  src={Images.faceBook}
                                  className={classes.socialImgStyle}
                              />
                            </div>
                          </a>
                      )}
                      {companyDetail?.instagram_url && (
                          <a
                              href={companyDetail.instagram_url}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <div className={classes.socialDiv}>
                              <img
                                  alt="Instagram"
                                  src={Images.insta}
                                  className={classes.socialImgStyle}
                              />
                            </div>
                          </a>
                      )}
                      {companyDetail?.linkedin_url && (
                          <a
                              href={companyDetail.linkedin_url}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <div className={classes.socialDiv}>
                              <img
                                  alt="LinkedIn"
                                  src={Images.linkedin}
                                  className={classes.socialImgStyle}
                              />
                            </div>
                          </a>
                      )}
                      {companyDetail?.twitter_url && (
                          <a
                              href={companyDetail.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            <div className={classes.socialDiv}>
                              <img
                                  alt="Twitter"
                                  src={Images.twitter}
                                  className={classes.socialImgStyle}
                              />
                            </div>
                          </a>
                      )}
                    </div>
                  </div>
              )}
              {/* Uncomment if needed
            <Typography className="regulerlargefont lightsilvercolor">
              Add information
            </Typography>
            */}
            </div>
          </div>
          <div className={classes.videoContainer}>
            <Typography className={classes.videoTitle}>Company video</Typography>
            <div className={classes.videoSectionStyle}>
              {videoUrl ? (
                  <div>
                    <video
                        width="100%"
                        className={classes.videoStyle}
                        controls
                        poster={isIOS ? Images.videoThumbnail : ""}
                    >
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                  </div>
              ) : (
                  <Typography>No Video</Typography>
              )}
              {(videoLoad || uploadLoader === "video") && (
                  <CircularProgress style={{ position: "absolute" }} />
              )}
            </div>
          </div>
          <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setRateModal(true);
              }}
              style={{ marginTop: 20 }}
          >
            Rate us
          </Button>
        </Grid>
    );
  }

  // Loader while fetching data
  if (pageLoad) {
    return (
        <Grid container>
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        </Grid>
    );
  }

  // Main render function
  function renderMain() {
    return (
        <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
          <Grid container>
            {renderLeft()}
            {renderRight()}
          </Grid>
          {/* Modals */}
          <MDetail
              visible={detailModal}
              handleModal={() => setDetailModal(false)}
          />
          <MRate
              visible={rateModal}
              handleModal={() => setRateModal(false)}
              ratings={rating}
          />
        </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
